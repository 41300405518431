/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Footers = () => {
    return (
        <>
            {/* start footer area */}
            <footer className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-5 col-sm-6 col-12">
                            <div className="footer-widget">
                                <div className="navbar-brand">
                                    <a href="/">
                                        <img src={process.env.REACT_APP_Url+"/assets/img/logos/icon_dark3.png"} alt="logo_light" style={{ width: '5rem' }} />
                                        <img src={process.env.REACT_APP_Url+"/assets/img/logos/logo-1.svg"} alt="logo-dark" style={{ width: '13rem' }} />
                                    </a>
                                </div>
                                <p>
                                    Ubah cara Anda dalam mempromosikan produk melalui internet. Bangun dan wujudkan website impian Anda bersama kami. Chat kami segera!
                                </p>
                                <div className="social-link">
                                    <a href="#" className="bg-tertiary" target="_blank">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a href="#" className="bg-success" target="_blank">
                                        <i className="fab fa-tumblr" />
                                    </a>
                                    <a href="#" className="bg-danger" target="_blank">
                                        <i className="fab fa-youtube" />
                                    </a>
                                    <a href="#" className="bg-info" target="_blank">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                    <a href="#" className="bg-pink" target="_blank">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-6 col-12">
                            <div className="footer-widget">
                                <h5>Our Services</h5>
                                <ul className="footer-quick-links">
                                    <li>
                                        <a href="/open-api">Open API</a>
                                    </li>
                                    {/* <li>
                                        <a href="#">SEO Optimizer</a>
                                    </li>
                                    <li>
                                        <a href="#">Digital Marketing</a>
                                    </li>
                                    <li>
                                        <a href="#">Market Monitor</a>
                                    </li>
                                    <li>
                                        <a href="#">Graphic Design</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-6 col-12">
                            {/* <div className="footer-widget">
                                <h5>Other Resources</h5>
                                <ul className="footer-quick-links">
                                    <li>
                                        <a href="about.html">About Us</a>
                                    </li>
                                    <li>
                                        <a href="pricing.html">Pricing</a>
                                    </li>
                                    <li>
                                        <a href="privacy-policy.html">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="services.html">Services</a>
                                    </li>
                                    <li>
                                        <a href="contact.html">Contact Us</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-6 col-12">
                            <div className="footer-widget">
                                <h5>Contact info</h5>
                                <div className="contact-info">
                                    <i className="envy envy-pin" />
                                    <p>Semarang</p>
                                </div>
                                <div className="contact-info">
                                    <i className="envy envy-call" />
                                    <p>
                                        <a href="tel:+628989750817">+628989750817</a>
                                    </p>
                                </div>
                                <div className="contact-info">
                                    <i className="envy envy-plane" />
                                    <p>
                                        <a href="mailTo:hello@solit.com">ivannofick@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* end footer area */}
            {/* start copy right area */}
            <div className="copy-right-area">
                <div className="container">
                    <div className="copy-right-content">
                        <p>
                            Copyright @<a href="https://ruangapp.com/" style={{color:'white'}} target="_blank" rel="noreferrer">
                                Ruangapp.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            {/* end copy right area*/}
        </>
    )
}

export default Footers