/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { Link } from 'react-router-dom'

const SignUp = () => {
    return (
        <>
            {/* signin Section */}
            <section className="signinup-section ptb-100 bg-thin">
                <div className="container">
                    <div className="signin-box">
                        {/* Title Box */}
                        <div className="title-box">
                            <h2>Sign up now</h2>
                            <div className="text">
                                Replenish him third creature and meat blessed void a fruit gathered.
                                Dolor sit amet, consectetur adipiscing elit. Phasellus feugiat elit
                                vitae enim lacinia semper.
                            </div>
                        </div>
                        {/* signin Form */}
                        <form>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            placeholder="enter name"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="email"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="name"
                                            placeholder="enter password"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="name"
                                            placeholder="confirm password"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="checkme"
                                        />
                                        <label className="form-check-label" htmlFor="checkme">
                                            Keep me Sign Up
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="cta-btn">
                                <button type="submit" className="btn btn-solid">
                                    sign up
                                </button>
                            </div>
                            {/* <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                <div className="social-link">
                                    <a href="#" className="bg-tertiary" target="_blank">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a href="#" className="bg-success" target="_blank">
                                        <i className="fab fa-tumblr" />
                                    </a>
                                    <a href="#" className="bg-info" target="_blank">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                </div>
                            </div> */}
                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                <div className="users">
                                    Already have an account <Link to={'/login'}>Sign in here</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* end signin section */}
        </>

    )
}

export default SignUp