import React from "react";
import { useSelector } from "react-redux";

const ShowProduct = () => {
  const { title, price } = useSelector((state) => state.product);
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Show Product</h5>
        <p className="card-text">
          <strong>Title: </strong>
          {title}
        </p>
        <p className="card-text">
          <strong>Price: </strong>
          {price}
        </p>
      </div>
    </div>
  );
};

export default ShowProduct;
