/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import ComingSoon from "../ComingSoon";

const OpenApi = () => {
    return (
        <>
            <ComingSoon />
        </>


    )
}

export default OpenApi