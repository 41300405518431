/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMahasiswas } from "../../redux/mahasiswaSlice";

import { AddProduct, InputCard, ShowCard, ShowProduct, TableSkelenton } from "../../components";

const TestRedux = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const { status } = useSelector((state) => state.mahasiswa);

  useEffect(() => {
    dispatch(getMahasiswas());
    setLoading(!loading);

  }, [dispatch]);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <AddProduct />
        </div>
        <div className="col-md-6">
          <ShowProduct />
        </div>
      </div>
      <div className="row mt-3">
        <h2>CRUD REDUX</h2>
        <div className="col-md-6">
          {loading ? <TableSkelenton /> : <ShowCard />}
        </div>
        <div className="col-md-6">
          {status === "Add" ? <InputCard /> : ""}
          {status === "Edit" ? <InputCard /> : ""}
        </div>
      </div>
    </div>
  );
};

export default TestRedux;
