/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'



const Header = () => {
    return (
        <>
            {/* Start header Area */}
            <header className="navbar-area nav-style-two">
                {/* menu for mobile device */}
                <div className="mobile-nav">
                    <a href="#" className="logo">
                        <img src={process.env.REACT_APP_Url + "/assets/img/logos/logo-2.svg"} alt="logo_light" style={{ width: '3rem' }} />
                        <img src={process.env.REACT_APP_Url + "/assets/img/logos/logo-2.svg"} alt="logo-dark" className='logo-dark-mobile' style={{ width: '3rem', filter: "brightness(0) saturate(100%) invert(21%) sepia(85%) saturate(2102%) hue-rotate(211deg)" }} />
                    </a>
                </div>
                {/* Menu for desktop device*/}
                <div className="main-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img src={process.env.REACT_APP_Url + "/assets/img/logos/logo-1.svg"} alt="logo_light" style={{
                                    width: '10rem'
                                }} />
                                <img src={process.env.REACT_APP_Url + "/assets/img/logos/logo-1.svg"} alt="logo-dark" className='logo-dark-dekstop' style={{ width: '10rem', filter: "brightness(0) saturate(100%) invert(21%) sepia(85%) saturate(2102%) hue-rotate(211deg)" }} />
                            </a>
                            <div
                                className="collapse navbar-collapse mean-menu"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <a href="/" className="nav-link">
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#top-feature" className="nav-link">
                                            Feature
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#about-container" className="nav-link">
                                            About Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#our-service" className="nav-link">
                                            Our Services
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a href="#pricing" className="nav-link">
                                            Pricing
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a href="/blog" className="nav-link">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                                <div className="search-item">
                                    <div className="search-btn">
                                        <i className="open-btn envy envy-magnify-glass" />
                                        <i className="close-btn envy envy-close" />
                                    </div>
                                    <div className="search-overlay search-popup">
                                        <form className="search-form">
                                            <input
                                                className="search-input"
                                                name="search"
                                                placeholder="Search"
                                                type="text"
                                            />
                                            <button className="btn btn-solid" type="submit">
                                                <i className="envy envy-magnify-glass" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div className="cta-btn">
                                    <a href="/login" className="btn btn-outline">
                                        <i className="envy envy-user" />
                                        log in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            {/* end header area */}
        </>


    )
}

export default Header;