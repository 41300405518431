import React from 'react'

const TopFeature = () => {
    return (
        <>
            {/* start top feature section */}
            <section className="top-feature-section ptb-100 bg-white" id="top-feature">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12">
                            <div className="section-title">
                                <span className="subtitle">top features</span>
                                <h2>Bagaimana Kami Dapat Membantu Membuat Bisnis Anda Sukses?</h2>
                            </div>
                            <div className="feature-text-blc">
                                <p>
                                Jika Anda mencari pemikir teknologi berbakat 
                                dan berdedikasi pada pekerjaan teknologi, 
                                kami hadir sebagai pengembangan perangkat 
                                lunak dinamis yang dapat membantu mengembangkan produk anda
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="single-feature">
                                        <div className="single-feature-content">
                                            {/* <i className="envy envy-cloud-computing1" /> */}
                                            <i className="envy envy-magnify-glass2" />
                                            <h3 className="mt-3">Market Research</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="single-feature">
                                        <div className="single-feature-content">
                                            <i className="envy envy-code2" />
                                            <h3 className="mt-3">
                                                Computing IT
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="single-feature">
                                        <div className="single-feature-content">
                                            <i className="envy envy-global" />
                                            <h3 className="mt-3">SEO Optimization</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="single-feature">
                                        <div className="single-feature-content">
                                            <i className="envy envy-server" />
                                            <h3 className="mt-3">
                                                Data Analytics
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape">
                    <img src="assets/img/resource/circle_shape.png" alt="circle" />
                    <span className="dot-1" />
                    <span className="dot-2" />
                    <span className="dot-3" />
                    <span className="dot-4" />
                    <span className="dot-5" />
                </div>
            </section>
            {/* end feature section */}
        </>

    )
}

export default TopFeature