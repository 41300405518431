/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import AssetsKu from './AssetsKu';
const LoadAssets = () => {
    // AssetsKu('./assets/js/jquery.min.js');
    // AssetsKu('./assets/js/bootstrap.bundle.min.js');
    // AssetsKu('./assets/js/jquery.magnific-popup.min.js');
    // AssetsKu('./assets/js/owl.carousel.min.js');
    // AssetsKu('./assets/js/meanmenu.min.js');
    // AssetsKu('./assets/js/form-validator.min.js');
    // AssetsKu('./assets/js/contact-form-script.js');
    // AssetsKu('./assets/js/jquery.ajaxchimp.min.js');
    AssetsKu(process.env.REACT_APP_Url+'/assets/js/main.js');

    return (
        <>
        </>
    )
}

export default LoadAssets;
