/* eslint-disable no-unused-vars */
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  Main,
  TestRedux,
  Login,
  Layout,
  Blogest,
  BlogDetail,
  NotFound,
  SignUp,
  OpenApi
} from "./pages";
import { LoadAssets } from "./components";

// eslint-disable-next-line
const axios = require("axios").default;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <LoadAssets />
    {/* <Loader /> */}
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route index path="/" element={<Main />} />
        <Route path="/" element={<Layout />}>
          <Route path="/open-api" element={<OpenApi />} />
          <Route path="/test-redux" element={<TestRedux />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/blog" element={<Blogest />} />
          <Route path="/blog/detail" element={<BlogDetail />} />
        </Route>
      </Routes>
      <ToastContainer position="bottom-right" />
    </BrowserRouter>
  </Provider>
);
