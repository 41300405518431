import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createMahasiswa, updateMahasiswa } from "../../redux/mahasiswaSlice";

const InputCard = () => {
  const { status, dataMahasiswa } = useSelector((state) => state.mahasiswa);
  const [nama, setNama] = useState("");
  const [jurusan, setJurusan] = useState("");
  const dispatch = useDispatch();
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(createMahasiswa({ nama, jurusan }));
    setNama("");
    setJurusan("");
  };
  const onUpdate = async (e) => {
    e.preventDefault();
    await dispatch(updateMahasiswa({ id: dataMahasiswa.id, nama, jurusan }));
    setNama("");
    setJurusan("");
  };
  useEffect(() => {
    if (status === "Edit") {
      setNama(dataMahasiswa.nama);
      setJurusan(dataMahasiswa.jurusan);
    }
  }, [dataMahasiswa, status]);
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          {status === "Add" ? "Tambah Mahasiswa" : "Edit Mahasiswa"}
        </h5>
        <form onSubmit={status === "Add" ? onCreate : onUpdate}>
          <div className="form-group">
            <label htmlFor="Nama">Nama</label>
            <input
              type="text"
              className="form-control"
              id="Nama"
              placeholder="Nama"
              value={nama}
              onChange={(e) => setNama(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="jurusan">Jurusan</label>
            <input
              type="text"
              className="form-control"
              id="jurusan"
              placeholder="jurusan"
              value={jurusan}
              onChange={(e) => setJurusan(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {status === "Add" ? "Tambah Mahasiswa" : "Edit Mahasiswa"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InputCard;
