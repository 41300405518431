import React from 'react'

const OurServices = () => {
    return (
        <>
            {/*  start service section */}
            <section className="service-section bg-white p-5" id="our-service">
                <div className="container">
                    <div className="section-title">
                        <span className="subtitle">our services</span>
                        <h2>Yuk, Cek Layanan Kami !</h2>
                        <p>
                            Tanpa harus memikirkan biaya dan waktu,
                            dengan layanan kami anda bisa mempromosikan bisnis dimana saja dan kapan saja dengan mudah dan aman.
                        </p>
                    </div>
                    <div className="row pt-70">
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-magnify-glass2" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_1.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">market research</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-code" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_2.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">digital Marketing</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-mic" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_3.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">Social Media</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-call-center" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_4.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">IT Consulting</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-server2" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_5.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">SEO &amp; Backlinks</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                            <div className="item-single">
                                <div className="icon">
                                    <i className="envy envy-server" />
                                    <div className="icon-bg">
                                        <img
                                            src="assets/img/resource/icon_shape_6.png"
                                            alt="icon_shape"
                                        />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>
                                        <a href="/">Hosting &amp; Email</a>
                                    </h6>
                                </div>
                            </div>
                            {/* item-single */}
                        </div>
                    </div>
                </div>
            </section>
            {/* end service section */}
        </>
    )
}

export default OurServices