import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getMahasiswas = createAsyncThunk(
  "Mahasiswas/getMahasiswas",
  async () => {
    const response = await axios.get("http://localhost:5000/mahasiswas");
    return response.data;
  }
);
export const createMahasiswa = createAsyncThunk(
  "Mahasiswas/createMahasiswa",
  async ({ nama, jurusan }) => {
    const response = await axios.post("http://localhost:5000/mahasiswas", {
      nama,
      jurusan,
    });
    return response.data;
  }
);
export const updateMahasiswa = createAsyncThunk(
  "Mahasiswas/updateMahasiswa",
  async ({ id, nama, jurusan }) => {
    const response = await axios.patch(
      `http://localhost:5000/mahasiswas/${id}`,
      {
        nama,
        jurusan,
      }
    );
    return response.data;
  }
);
export const deleteMahasiswa = createAsyncThunk(
  "Mahasiswas/deleteMahasiswa",
  async (id) => {
    await axios.delete(`http://localhost:5000/mahasiswas/${id}`);
    return id;
  }
);
const mahasiswaEntity = createEntityAdapter({
  selectId: (mahasiswa) => mahasiswa.id,
});

const mahasiswaSlice = createSlice({
  name: "mahasiswa",
  initialState: {
    ...mahasiswaEntity.getInitialState(),
    status: "",
    dataMahasiswa: {},
  },
  reducers: {
    mahasiswaReducer: (state, action) => {
      state.status = action.payload.status;
      state.dataMahasiswa = action.payload.dataMahasiswa;
    },
  },
  extraReducers: {
    [getMahasiswas.fulfilled]: (state, action) => {
      mahasiswaEntity.setAll(state, action.payload);
    },
    [createMahasiswa.fulfilled]: (state, action) => {
      mahasiswaEntity.addOne(state, action.payload);
    },
    [updateMahasiswa.fulfilled]: (state, action) => {
      mahasiswaEntity.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    [deleteMahasiswa.fulfilled]: (state, action) => {
      mahasiswaEntity.removeOne(state, action.payload);
    },
  },
});
export const mahasiswaSelectors = mahasiswaEntity.getSelectors(
  (state) => state.mahasiswa
);
export const { mahasiswaReducer } = mahasiswaSlice.actions;
export default mahasiswaSlice.reducer;
