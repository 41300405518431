/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getBlog } from '../../services';

const Blogest = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    // eslint-disable-next-line no-undef
    useEffect(() => {
        if (loading) {
            testApi()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    const testApi = async () => {
        const response = await getBlog();
        if (response.error) {
            toast.error(response.message);
        } else {
            setData(response.data);
            setLoading(false);
            toast.success(response.message);
        }
    }


    return (
        <>
            {/*start blog section*/}
            <section className="blog-grid ptb-100 bg-thin">

                <div className="container">
                    <div className="row justify-content-center">
                        {
                            (data.length > 0 ? (
                                data.map((datas, index) => {
                                    return (
                                        <div className="col-lg-4" key={'a1_' + Math.random()}>
                                            <div className="blog-item-single">
                                                <div className="blog-item-img">
                                                    <a href="blog-details.html">
                                                        <img src="assets/img/blog/blog_1.png" alt="blog-bg-image" />
                                                    </a>
                                                    <p className="tag">Design</p>
                                                </div>
                                                <div className="blog-item-content">
                                                    <span>
                                                        {" "}
                                                        <i className="envy envy-calendar" />
                                                        August 31, 2021{" "}
                                                    </span>
                                                    <a href="blog-details.html">
                                                        {/* <h3>Content Tools &amp; Techniques are good</h3> */}
                                                        <h3>{datas.title}</h3>
                                                    </a>
                                                    <p>
                                                        Strategy experience and analytical expert is combine to enable.
                                                        Strate great experience and analysis the content.
                                                    </p>
                                                    <a
                                                        href="blog-details.html"
                                                        target="_self"
                                                        className="btn btn-text-only"
                                                    >
                                                        read more
                                                        <i className="envy envy-right-arrow" />
                                                    </a>
                                                </div>
                                                {/* blog-item-content */}
                                            </div>
                                            {/* blog-item-single */}
                                        </div>

                                    )

                                })
                            ) : '')
                        }
                    </div>
                    {/* row */}
                    <div className="cta-btn">
                        <a href="blog-grid.html" className="btn btn-solid">
                            Load more <i className="envy envy-right-arrow" />{" "}
                        </a>
                    </div>
                </div>
            </section>
            {/*end blog section*/}
        </>

    )
}

export default Blogest