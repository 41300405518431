import React from "react";
import { Header, Home, TopFeature, AboutUs, OurServices, Footers } from "../../components";

const Main = () => {
  return (
    <>
      <Header />
      <Home />
      <TopFeature />
      <AboutUs />
      <OurServices />
      {/* <OurPricing /> */}
      <Footers />
    </>
  );
};

export default Main;
