import React from "react";
import { Outlet } from "react-router-dom";
import { Header, Footers } from "../../components";

const Layout = () => {
    return (
        <>
            <Header />
                {/* <div className="py-100px"> */}
                <div>
                    <Outlet />
                </div>
            <Footers />
        </>
    );
};

export default Layout;