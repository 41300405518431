/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
    return (
        <>
            {/* signin Section */}
            <section className="signinup-section bg-blacked">
                <div className="container">
                    <div className="signin-box">
                        {/* Title Box */}
                        <div className="title-box">
                            <h2>Sign in now</h2>
                            {/* <div className="text">
                                Replenish him third creature and meat blessed void a fruit gathered.
                                Dolor sit amet, consectetur adipiscing elit. Phasellus feugiat elit
                                vitae enim lacinia semper.
                            </div> */}
                        </div>
                        {/* signin Form */}
                        <form className="signin-form">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="email"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="name"
                                            placeholder="enter password"
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 form-check-box">
                                    <div className="form-check">
                                        {/* <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="checkme"
                                        />
                                        <label className="form-check-label" htmlFor="checkme">
                                            Keep Sign In
                                        </label> */}
                                    </div>
                                    <p className="forgot-password">
                                        <a href="#">Forgot Password?</a>
                                    </p>
                                </div>
                            </div>
                            <div className="cta-btn">
                                <button type="submit" className="btn btn-solid">
                                    sign in
                                </button>
                            </div>
                            {/* <div className="form-group col-lg-12">
                                <div className="social-link">
                                    <a href="#" className="bg-tertiary" target="_blank">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a href="#" className="bg-success" target="_blank">
                                        <i className="fab fa-tumblr" />
                                    </a>
                                    <a href="#" className="bg-info" target="_blank">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                </div>
                            </div> */}
                            <div className="form-group col-lg-12">
                                <div className="users">
                                    Don’t have an account  <Link to={'/sign-up'}>Sign up here</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* end signin section */}
        </>
    )
}

export default Login