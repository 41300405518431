
/* eslint-disable jsx-a11y/img-redundant-alt */

import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
        <div className="page-title-area ptb-100 bg-thin">
            <div className="container">
                <div className="page-title-content">
                    <h1>Error page</h1>
                    <ul>
                        <li className="item"><Link to={'/'}>Home</Link></li>
                        <li className="item"><a href="error-404.html">Error page</a></li>
                    </ul>
                </div>
            </div>
            <div className="shape">
                <span className="shape1"></span>
                <span className="shape2"></span>
                <span className="shape3"></span>
                <span className="shape4"></span>
            </div>
        </div>
        <section className="error-area ptb-100 bg-thin">
            <div className="container">
                <div className="error-content">
                    <img src="assets/img/error.png" alt="image" />
                    <h3>Ooops! Page Not Found</h3>
                    <p>
                        The page you are looking for might have been removed had its name changed or is temporarily unavailable.
                    </p>
                </div>
                <div className="cta-btn">
                    <Link to={'/'} className="btn btn-solid">Back to Homepage</Link>
                </div>
            </div>
        </section>
    </>
  )
}

export default NotFound