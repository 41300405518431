import axios from 'axios'
import Cookies from 'js-cookie'

export default async function CallAPI({
    url, method, data, token, serverToken,
}) {
    let headers = {}
    if (serverToken) {
        headers = {
            authorization: `Bearer ${serverToken}`,
        }
    } else if (token) {
        const tokenCookies = Cookies.get('token')
        if (tokenCookies) {
            // const jwtToken = atob(tokenCookies)
            headers = {
                authorization: `Bearer ${tokenCookies}`,
            }
        }
    }

    const response = await axios({ url, method, data, headers }).catch((err) => err.response)
    const { length } = Object.keys(response.data)

    // DISESUAIKAN DENGAN RESPON SCHEMA API DARI BACKEND
    if (response.status > 300) {
        const res = {
            error: true,
            message: response.data.message === '' ? 'Failed' : response.data.message,
            data: '',
        }
        return res
    } else {
        const res = {
            error: false,
            message: 'success',
            data: length > 1 ? response.data : response.data.data
        }
        return res
    }
}
