import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMahasiswa,
  getMahasiswas,
  mahasiswaReducer,
  mahasiswaSelectors,
} from "../../redux/mahasiswaSlice";

const ShowCard = () => {
  const dispatch = useDispatch();
  const mahasiswas = useSelector(mahasiswaSelectors.selectAll);

  const showCardInput = (val, data) => {
    dispatch(mahasiswaReducer({ status: val, dataMahasiswa: data }));
  };

  useEffect(() => {
    dispatch(getMahasiswas());
  }, [dispatch]);
  return (
    // create card table
    <div className="card">
      <div className="card-body">
        <button
          className="badge bg-success"
          onClick={() => showCardInput("Add")}
        >
          Tambah
        </button>
        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Jurusan</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {mahasiswas.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.nama}</td>
                <td>{item.jurusan}</td>
                <td>
                  <button
                    className="badge bg-info"
                    onClick={() => showCardInput("Edit", item)}
                  >
                    Edit
                  </button>
                  <button
                    className="badge bg-danger"
                    onClick={() => dispatch(deleteMahasiswa(item.id))}
                  >
                    Hapus
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShowCard;
