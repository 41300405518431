import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkelenton = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} height={30} />
                </th>
                <th>
                  <Skeleton width={100} height={30} />
                </th>
                <th>
                  <Skeleton width={100} height={30} />
                </th>
                <th>
                  <Skeleton width={100} height={30} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableSkelenton;
