/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

const BlogDetail = () => {
    return (
        <>
            {/* Start Blog Details section */}
            <section className="blog-details-section ptb-100 bg-thin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="image">
                                    <img src="./assets/img/blog/blog_details.jpg" alt="image" />
                                </div>
                                <ul className="post-meta">
                                    <li>
                                        <i className="envy envy-calendar" />
                                        <a href="#">Sepetember 31, 2021</a>
                                    </li>
                                    <li>
                                        <i className="envy envy-comment" />
                                        03 comments
                                    </li>
                                </ul>
                                <div className="content">
                                    <h2>Content Ideation: Tools &amp; Techniques</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida. Risus commodo viverra
                                        maecenas accumsan lacus vel facilisis. At vero eos et accusam
                                        et.
                                    </p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore dolore magna aliqua. Quis
                                        ipsum suspendisse ultrices gravida. Risus commodo
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="image">
                                            <img src="assets/img/blog/blog_4.png" alt="blog-image" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="image">
                                            <img src="assets/img/blog/blog_1.png" alt="blog-image" />
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore dolore magna aliqua. Quis
                                    ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                                    accumsan lacus vel facilisis.
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore dolore magna aliqua. Quis
                                    ipsum suspendisse ultrices gravida. Risus commodo
                                </p>
                                <blockquote className="blockquote clearfix">
                                    <img
                                        src="assets/img/resource/quotation_big.png"
                                        alt="blog-image"
                                    />
                                    <p>
                                        You cannot manage productivity, you must provide the tools to
                                        let people become their best.
                                    </p>
                                    <span>steve jobs</span>
                                </blockquote>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore dolore magna aliqua. Quis
                                    ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                                    accumsan lacus vel facilisis.
                                </p>
                                <div className="article-share">
                                    <div className="tags pb-3">
                                        <span>tags:</span>
                                        <a href="#">Design</a>
                                        <a href="#">Development</a>
                                        <a href="#">Technique</a>
                                    </div>
                                    <div className="social-link">
                                        <a href="#" className="bg-tertiary" target="_blank">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a href="#" className="bg-success" target="_blank">
                                            <i className="fab fa-tumblr" />
                                        </a>
                                        <a href="#" className="bg-danger" target="_blank">
                                            <i className="fab fa-youtube" />
                                        </a>
                                        <a href="#" className="bg-info" target="_blank">
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                        <a href="#" className="bg-pink" target="_blank">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                                <hr />
                                <div className="related-post ptb-30">
                                    <h2>related post</h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="blog-item-single">
                                                <div className="blog-item-img">
                                                    <a href="blog-details.html">
                                                        <img
                                                            src="assets/img/blog/blog_3.png"
                                                            alt="blog-bg-image"
                                                        />
                                                    </a>
                                                    <p className="tag">Design</p>
                                                </div>
                                                <div className="blog-item-content">
                                                    <span>
                                                        {" "}
                                                        <i className="envy envy-calendar" />
                                                        May 31, 2021{" "}
                                                    </span>
                                                    <a href="blog-details.html">
                                                        <h3>The great moment journey in our business</h3>
                                                    </a>
                                                    <p>
                                                        Ipsum dolor sit amet consectetur adipisicing elit. Harum
                                                        consequatur laborum nulla, minus commodi blanditiis rem
                                                        explicabo.
                                                    </p>
                                                    <a
                                                        href="blog-details.html"
                                                        target="_self"
                                                        className="btn btn-text-only"
                                                    >
                                                        read more
                                                        <i className="envy envy-right-arrow" />
                                                    </a>
                                                </div>
                                                {/* blog-item-content */}
                                            </div>
                                            {/* blog-item-single */}
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="blog-item-single">
                                                <div className="blog-item-img">
                                                    <a href="blog-details.html">
                                                        <img
                                                            src="assets/img/blog/blog_2.png"
                                                            alt="blog-bg-image"
                                                        />
                                                    </a>
                                                    <p className="tag">Brand</p>
                                                </div>
                                                <div className="blog-item-content">
                                                    <span>
                                                        {" "}
                                                        <i className="envy envy-calendar" />
                                                        March 31, 2021{" "}
                                                    </span>
                                                    <a href="blog-details.html">
                                                        <h3>The State of Local SEO Industry Report 2021</h3>
                                                    </a>
                                                    <p>
                                                        Lorem ipsum dolor sit, amet consectetur adipisicing
                                                        elit. Magni laborum minima enim ab! Asperiores, quos!
                                                    </p>
                                                    <a
                                                        href="blog-details.html"
                                                        target="_self"
                                                        className="btn btn-text-only"
                                                    >
                                                        read more
                                                        <i className="envy envy-right-arrow" />
                                                    </a>
                                                </div>
                                                {/* blog-item-content */}
                                            </div>
                                            {/* blog-item-single */}
                                        </div>
                                    </div>
                                </div>
                                <div className="comments-area">
                                    <h2 className="comment-title">
                                        comments <span>(03)</span>
                                    </h2>
                                    <ol className="comment-list">
                                        <li className="comment">
                                            <article className="comment-body">
                                                <div className="comment-author">
                                                    <img
                                                        src="assets/img/team/team_3.jpg"
                                                        className="avatar"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="comment-content clearfix">
                                                    <div className="comment-metadata">
                                                        <h6 className="name">John Jones</h6>
                                                        <span className="time">April 24, 2021</span>
                                                        <div className="reply">
                                                            <a href="#" className="btn btn-solid">
                                                                Reply
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing
                                                        elit. A laudantium distinctio ea reprehenderit est
                                                        laborum!
                                                    </p>
                                                </div>
                                            </article>
                                            <ol className="children">
                                                <li className="comment">
                                                    <article className="comment-body">
                                                        <div className="comment-author">
                                                            <img
                                                                src="assets/img/team/team_2.jpg"
                                                                className="avatar"
                                                                alt="image"
                                                            />
                                                        </div>
                                                        <div className="comment-content clearfix">
                                                            <div className="comment-metadata">
                                                                <h6 className="name">Davy Jones</h6>
                                                                <span className="time">April 24, 2021</span>
                                                                <div className="reply">
                                                                    <a href="#" className="btn btn-solid">
                                                                        Reply
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                Lorem ipsum dolor sit amet consectetur adipisicing
                                                                elit. Possimus molestias sed quasi doloribus
                                                                obcaecati rerum et commodi quia repudiandae
                                                                excepturi?
                                                            </p>
                                                        </div>
                                                    </article>
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="comment">
                                            <article className="comment-body">
                                                <div className="comment-author">
                                                    <img
                                                        src="assets/img/team/team_3.jpg"
                                                        className="avatar"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="comment-content clearfix">
                                                    <div className="comment-metadata">
                                                        <h6 className="name">John Jones</h6>
                                                        <span className="time">April 24, 2021</span>
                                                        <div className="reply">
                                                            <a href="#" className="btn btn-solid">
                                                                Reply
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Strategy experience and analytical expert ise combine to
                                                        enable. Strate gy experi ence and analy sis the content.
                                                    </p>
                                                </div>
                                            </article>
                                        </li>
                                    </ol>
                                </div>
                                <div className="comment-reply">
                                    <h2 className="comment-reply-title">Leave a comment</h2>
                                    <form className="comment-form">
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <textarea
                                                        name="message"
                                                        className="form-control"
                                                        placeholder="comment"
                                                        required="required"
                                                        rows={6}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-xs-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="name"
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-xs-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="email"
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-solid">
                                            submit comment
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <section className="widget widget-author">
                                    <div className="author-img">
                                        <img src="assets/img/team/team_4.jpg" alt="author" />
                                    </div>
                                    <h5>About Author</h5>
                                    <p>
                                        Hello I'm <a href="#">Angela</a>. A User Interface Designer.
                                        Love to do Creative Work.
                                    </p>
                                    <p>follow me</p>
                                    <div className="social-link">
                                        <a href="#" className="bg-success" target="_blank">
                                            <i className="fab fa-tumblr" />
                                        </a>
                                        <a href="#" className="bg-info" target="_blank">
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                        <a href="#" className="bg-pink" target="_blank">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </section>
                                <div className="widget widget-search">
                                    <form className="search-form search-top">
                                        <input
                                            type="search"
                                            className="search-field"
                                            placeholder="Search article"
                                        />
                                        <button type="submit" className="btn-text-only">
                                            <i className="envy envy-magnify-glass" />
                                        </button>
                                    </form>
                                </div>
                                <section className="widget widget-article">
                                    <h5 className="widget-title">Recent articles</h5>
                                    <article className="article-item">
                                        <a href="#" className="article-img">
                                            <img
                                                src="assets/img/blog/recent_article_1.png"
                                                alt="blog-image"
                                            />
                                        </a>
                                        <div className="info">
                                            <span className="time">
                                                <i className="envy envy-calendar" />
                                                Sepetember 31, 2021
                                            </span>
                                            <h6 className="title">
                                                <a href="#">The Biggest Mistake When Setting New Goals. </a>
                                            </h6>
                                        </div>
                                    </article>
                                    <article className="article-item">
                                        <a href="#" className="article-img">
                                            <img
                                                src="assets/img/blog/recent_article_2.png"
                                                alt="blog-image"
                                            />
                                        </a>
                                        <div className="info">
                                            <span className="time">
                                                <i className="envy envy-calendar" />
                                                Sepetember 31, 2021
                                            </span>
                                            <h6 className="title">
                                                <a href="#">The Biggest Mistake When Setting New Goals. </a>
                                            </h6>
                                        </div>
                                    </article>
                                    <article className="article-item">
                                        <a href="#" className="article-img">
                                            <img
                                                src="assets/img/blog/recent_article_3.png"
                                                alt="blog-image"
                                            />
                                        </a>
                                        <div className="info">
                                            <span className="time">
                                                <i className="envy envy-calendar" />
                                                Sepetember 31, 2021
                                            </span>
                                            <h6 className="title">
                                                <a href="#">The Biggest Mistake When Setting New Goals. </a>
                                            </h6>
                                        </div>
                                    </article>
                                </section>
                                <section className="widget widget-categories">
                                    <h5 className="widget-title">Categories</h5>
                                    <ul className="categorie-list">
                                        <li>
                                            <a href="#">Design</a>
                                            <span className="total">17</span>
                                        </li>
                                        <li>
                                            <a href="#">Development</a>
                                            <span className="total">34</span>
                                        </li>
                                        <li>
                                            <a href="#">Innovation</a>
                                            <span className="total">10</span>
                                        </li>
                                        <li>
                                            <a href="#">research</a>
                                            <span className="total">35</span>
                                        </li>
                                    </ul>
                                </section>
                                <section className="widget widget-gallery">
                                    <h5 className="widget-title">gallery</h5>
                                    <div className="gallery-item">
                                        <img src="assets/img/blog/blog_3.png" alt="blog-image" />
                                        <img src="assets/img/blog/blog_7.png" alt="blog-image" />
                                        <img src="assets/img/blog/blog_8.png" alt="blog-image" />
                                        <img src="assets/img/blog/blog_6.png" alt="blog-image" />
                                        <img src="assets/img/blog/blog_4.png" alt="blog-image" />
                                        <img src="assets/img/blog/blog_5.png" alt="blog-image" />
                                    </div>
                                </section>
                                <section className="widget widget-tag">
                                    <h5 className="widget-title">Tags</h5>
                                    <div className="tags">
                                        <a href="#"> Desing </a>
                                        <a href="#"> development </a>
                                        <a href="#"> Technique </a>
                                        <a href="#"> trendy </a>
                                        <a href="#"> art </a>
                                        <a href="#"> deployment </a>
                                        <a href="#"> Technical </a>
                                        <a href="#"> Technology </a>
                                        <a href="#"> apps </a>
                                        <a href="#"> mobile </a>
                                        <a href="#"> start up </a>
                                        <a href="#"> UI/UX </a>
                                        <a href="#"> tips </a>
                                    </div>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Blog Details Area */}
        </>

    )
}

export default BlogDetail