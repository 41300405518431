/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

const ComingSoon = () => {

    // const timer = () => {
    //     // Count Time 
    //     function makeTimer() {
    //         var endTime = new Date('January 01, 2023 00:00:00 PDT');
    //         console.log(endTime);
    //         var endTime = (Date.parse(endTime)) / 1000;
    //         var now = new Date();
    //         var now = (Date.parse(now) / 1000);
    //         var timeLeft = endTime - now;
    //         var days = Math.floor(timeLeft / 86400);
    //         var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    //         var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
    //         var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
    //         if (hours < '10') {
    //             hours = '0' + hours;
    //         }
    //         if (minutes < '10') {
    //             minutes = '0' + minutes;
    //         }
    //         if (seconds < '10') {
    //             seconds = '0' + seconds;
    //         }
    //         $('#days').html(days + '<span>Days</span>');
    //         $('#hours').html(hours + '<span>Hours</span>');
    //         $('#minutes').html(minutes + '<span>Minutes</span>');
    //         $('#seconds').html(seconds + '<span>Seconds</span>');
    //     }
    //     setInterval(function () {
    //         makeTimer();
    //     }, 0);
    // }


    return (
        <>
            {/* signin Section */}
            <section className="comingsoon-section">
                <div className="container">
                    <div className="comingsoon-box" style={{ backgroundColor: "#7f7f7f" }}>
                        <div className="logo">
                            <img src={process.env.REACT_APP_Url + "/assets/img/logos/icon_dark3.png"} alt="logo" />
                        </div>
                        {/* Title Box */}
                        <div className="title-box">
                            <h2>coming soon</h2>
                            <div className="text">
                                Replenish him third creature and meat blessed void a fruit
                            </div>
                        </div>
                        <div id="timer" className="flex-wrap d-flex justify-content-center">
                            <div
                                id="days"
                                className="align-items-center flex-column d-flex justify-content-center"
                            />
                            <div
                                id="hours"
                                className="align-items-center flex-column d-flex justify-content-center"
                            />
                            <div
                                id="minutes"
                                className="align-items-center flex-column d-flex justify-content-center"
                            />
                            <div
                                id="seconds"
                                className="align-items-center flex-column d-flex justify-content-center"
                            />
                        </div>
                        <div className="social-link text-center">
                            <a href="#" className="bg-tertiary" target="_blank">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a href="#" className="bg-success" target="_blank">
                                <i className="fab fa-tumblr" />
                            </a>
                            <a href="#" className="bg-danger" target="_blank">
                                <i className="fab fa-youtube" />
                            </a>
                            <a href="#" className="bg-info" target="_blank">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            <a href="#" className="bg-pink" target="_blank">
                                <i className=" fab fa-instagram" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>


    )
}

export default ComingSoon