import React from 'react'

const Home = () => {
  return (
    <div id="home" className="home-banner-area banner-type-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="banner-content">
              <h1>Find You Want Now!</h1>
              <p style={{textAlign: 'justify'}}>
              Ubah cara Anda dalam mempromosikan produk melalui internet. 
              Bangun dan wujudkan website impian Anda bersama kami. Chat kami segera!
              </p>
              <div className="cta-btn">
                <a href="/" className="btn btn-solid"
                  >Try for free</a
                >
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="banner-image">
              <img src="assets/img/banner/banner_2.png" alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home