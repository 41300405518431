import CallAPI from "./helper";


const ROOT_API = process.env.REACT_APP_ROOT_API
// const API_VER = 'v1'

export async function getBlog() {
    const url = `${ROOT_API}/blog/get/data?published=1`

    return CallAPI({
        url,
        method: 'GET',
        token: false,
    })
}