import React from 'react'

const AboutUs = () => {
    return (
        <>
            {/*start about section*/}
            <section id="about" className="about-section about-two pt-100 pb-70 bg-blacked">
                <div className="container" id="about-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12 pb-30">
                            <div className="about-img">
                                <div className="tips-img">
                                    <img src="./assets/img/tips_left.png" alt="tips" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 pb-30">
                            <div className="section-title">
                                <span className="subtitle">About Us</span>
                                <h2>Layanan Teknologi Terkelola di Berbagai Bisnis</h2>
                            </div>
                            <div className="about-text-blc">
                                <p style={{textAlign: 'justify'}}>
                                    Infrastruktur teknologi yang optimal merupakan sebuah kewajiban agar bisnis Anda efisien dan menguntungkan.
                                    Kami memberikan penawaran layanan yang terkelola dengan lengkap sehingga
                                    anda dapat terbantu dalam pekerjaan teknologi di usaha anda{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*end about section*/}
        </>
    )
}

export default AboutUs